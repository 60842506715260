
import React from "react";

function Footer() {
  return (
    <div className="section-dark section" >
      <h6 className="category" style={{
        width: "100%", textAlign: "center"
      }}>
        oeuvres crées et site développé par {" "}
        <a
          href="https://julie-ramadanoski.dev"
          target="_blank" rel="noreferrer"
        >
          <img
            alt="Julie Ramadanoski développeuse d'applications e-novantes"
            className="creative-tim-logo"
            src={require("assets/img/JuliePortrait-logo-white.png")}
            style={{
              maxWidth: "140px",
              top: "-2px",
              left: "3px",
              position: "relative",
              verticalAlign: "middle"
            }}
          />
        </a>
      </h6>
    </div>
  );
}

export default Footer;
