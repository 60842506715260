import React from "react";
import NftsDetails from "components/NftsDetails"

function SectionCards() {
  return (
    <>
      <div className="section section-cards section-dark">
        <div className="codrops-header" style={{ minHeight: "900px" }}>
          <NftsDetails />
        </div>
        <section className="section-intro">
          <div className="isolayer isolayer--deco1 isolayer--shadow js">
            <ul className="grid grid--loaded">
              <li className="grid__item first-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Sunset Men French Weightlifting Club"
                    title="Faites de schoix qui vous ressemble"
                    className="grid__img layer"
                    src={require("assets/img/31.png")}
                  />
                </a>
              </li>
              <li className="grid__item third-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Brown Men French Weightlifting Club"

                    title="Musclez votre volonté"
                    className="grid__img layer"
                    src={require("assets/img/8.png")}
                  />
                </a>
              </li>
              <li className="grid__item fourth-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="White Men French Weightlifting Club"
                    title="Mettez en place vos nouvelles habitudes"
                    className="grid__img layer"
                    src={require("assets/img/7.png")}
                  />
                </a>
              </li>
              <li className="grid__item fifth-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Yellow Men French Weightlifting Club"
                    title="remplacez vos addictions"
                    className="grid__img layer"
                    src={require("assets/img/12.png")}
                  />
                </a>
              </li>
              <li className="grid__item sixth-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Rainbow Men French Weightlifting Club"
                    title="Atteignez vos objectifs"
                    className="grid__img layer"
                    src={require("assets/img/4.png")}
                  />
                </a>
              </li>
              <li className="grid__item seventh-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Teal Men French Weightlifting Club"
                    title="Soyez fier de vous"
                    className="grid__img layer"
                    src={require("assets/img/20.png")}
                  />
                </a>
              </li>
              <li className="grid__item eight-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="White Men French Weightlifting Club"
                    title="Prouvez-vous que vous en êtes capable"
                    className="grid__img layer"
                    src={require("assets/img/7.png")}
                  />
                </a>
              </li>
              <li className="grid__item ninth-card">
                <a
                  className="grid__link"
                  href="#weightlifter"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Transgender Men French Weightlifting Club"
                    title="Croire est la première étape"
                    className="grid__img layer"
                    src={require("assets/img/1.png")}
                  />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div >
    </>
  );
}

export default SectionCards;
