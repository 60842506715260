import { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";

import ColorNavbar from "./components/ColorNavbar.js";
import PresentationHeader from "components/PresentationHeader.js";
import SectionCards from "./components/SectionCards.js";
import SectionNewsletter from "./components/SectionNewsletter.js";
import Footer from 'components/Footer';

import { EthProvider } from "contexts/EthContext";

function App() {
  useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });

  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;

      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");

      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };

  return (
    <EthProvider>
      <BrowserRouter>
        <div className="App">
          <ColorNavbar />
          <PresentationHeader />
          <SectionCards />
          <SectionNewsletter />
          <Footer />
        </div>
      </BrowserRouter>
    </EthProvider>
  );
}

export default App;
