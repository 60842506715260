import React, { useReducer, useCallback, useEffect } from "react";
import Web3 from "web3";
import EthContext from "./EthContext";
import artifact from "artifacts/contracts/FrenchWeightLifitingClub.sol/MenFrenchWeightlifitngClub";
import { reducer, actions, initialState } from "./state";

function EthProvider({ children }) {
  const { abi } = artifact;
  const [state, dispatch] = useReducer(reducer, initialState);

  function getWeb3() {
    return new Web3(Web3.givenProvider || "ws://localhost:8545");
  }

  const getNetworkName = (networkID) => {
    switch (networkID) {
      case 1:
        return "Ethereum mainnet";
      case 5:
        return "Goerli - Ethereum testnet";
      case 8545:
        return "ganache - Ethereum local";
      default:
        return "une Blockchain non supportée";
    }
  };

  const getContractAddress = (networkID) => {
    let contractAddress = null;
    switch (networkID) {
      case 1: // Ethereum-
        contractAddress = process.env.REACT_APP_MFWC_MAINNET_PUBLIC_ADDRESS;
        break;
      case 5: // Goerli
        contractAddress = process.env.REACT_APP_MFWC_GOERLI_PUBLIC_ADDRESS;
        break;
      case 8545: // ganache
        contractAddress = process.env.REACT_APP_MFWC_GANACHE_PUBLIC_ADDRESS;
        break;
      default:
        dispatch({
          type: actions.error,
          data: "Non disponible sur cette blockchain. Connectez vous sur Ethereum",
        });
        break;
    }
    return contractAddress;
  };

  const getAccount = useCallback(async () => {
    const web3 = getWeb3();
    const accounts = await web3.eth.requestAccounts();
    return accounts[0];
  }, []);

  const getContractAndNetwork = useCallback(async () => {
    try {
      const web3 = getWeb3();
      const networkID = await web3.eth.net.getId();

      // Contract
      const address = getContractAddress(networkID);
      const networkName = getNetworkName(networkID);
      const gasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(abi, address);

      dispatch({
        type: actions.init,
        data: {
          networkID,
          networkName,
          contract,
          gasPrice,
          errorMessage: null,
        },
      });
    } catch (err) {
      dispatch({
        type: actions.error,
        data: err.message,
      });
    }
  }, [abi]);

  const getContractData = useCallback(
    async (account) => {
      try {
        // get contract blockchain data
        const contract = state.contract;
        const cost = await contract.methods.cost().call({ from: account });
        const totalSupply = await contract.methods
          .totalSupply()
          .call({ from: account });

        const contractData = {
          cost,
          totalSupply,
        };

        dispatch({
          type: actions.update,
          data: {
            contractData,
            errorMessage: null,
          },
        });
      } catch (err) {
        dispatch({
          type: actions.error,
          data: err.message,
        });
      }
    },
    [state.contract]
  );

  const getUserData = useCallback(
    async (account) => {
      try {
        // get contract Owner
        const contract = state.contract;
        const owner = await contract.methods.owner().call({ from: account });
        const isWhitelisted = await contract.methods
          .whitelisted(account)
          .call({ from: account });
        const nftCollection = await contract.methods
          .walletOfOwner(account)
          .call({ from: account });

        // create user profile
        const me = {
          address: account,
          isOwner: account === owner,
          isWhitelisted,
          nftCollection,
        };

        dispatch({
          type: actions.update,
          data: {
            me,
            errorMessage: null,
          },
        });
      } catch (err) {
        dispatch({
          type: actions.error,
          data: err.message,
        });
      }
    },
    [state.contract]
  );

  const reload = useCallback(async () => {
    if (artifact) {
      getContractAndNetwork();
    } else {
      dispatch({
        type: actions.error,
        data: "Pas d'artéfact détecté",
      });
    }
  }, [getContractAndNetwork]);

  useEffect(() => {
    async function fetchData() {
      if (state.contract) {
        const account = await getAccount();
        getUserData(account);
        getContractData(account);
      }
    }
    fetchData();
  }, [getAccount, getContractData, getUserData, state.contract]);

  useEffect(() => {
    if (state.askConnection > 0) {
      reload();
    }
  }, [reload, state.askConnection]);

  useEffect(() => {
    if (window.ethereum || window.web3) {
      const events = ["chainChanged", "accountsChanged"];
      const handleChange = (e) => {
        dispatch({
          type: actions.init,
          data: {
            ...initialState,
          },
        });

        reload();
      };
      events.forEach((e) => window.ethereum.on(e, () => handleChange(e)));

      return () => {
        events.forEach((e) =>
          window.ethereum.removeListener(e, () => handleChange(e))
        );
      };
    }
  }, [reload]);

  return (
    <EthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </EthContext.Provider>
  );
}

export default EthProvider;
