import { useState } from "react";
import classnames from "classnames";
import {
  Button,
  Navbar,
  NavItem,
  Nav,
  Spinner
} from "reactstrap";

// context
import useEth from "contexts/EthContext/useEth";
import truncateEthAddress from "utils/truncate-eth-address";

function ColorNavbar() {
  const {
    state: { me, contract, networkID, networkName }, dispatch
  } = useEth();

  const [withdrawing, setWithdrawing] = useState({
    tx: null,
    loading: false,
    error: null
  });

  async function withdraw() {
    if (typeof window.ethereum !== 'undefined') {
      try {
        setWithdrawing({
          tx: null,
          loading: true,
          error: null,
          isSuccess: false
        })

        const res = await contract.methods
          .withdraw()
          .send({ from: me.address });

        setWithdrawing({
          tx: res.message,
          loading: false,
          error: null,
          isSuccess: true
        })
      } catch (err) {
        setWithdrawing({
          tx: null,
          loading: false,
          error: err.message,
          isSuccess: false
        })
      }
    }
  }

  const handleConnectWallet = () => {
    dispatch({
      type: "ASK_CONNECTION",
    });
  }

  return (

    <Navbar
      className={classnames("fixed-top", "navbar-transparent")}
      expand="lg"
      id="navbar-main"
    >

      <Nav className="ml-auto" fill>
        {networkID > 1 && <NavItem>
          <Button
            className="btn-round"
            color="warning"
            outline
          >Vous êtes sur {networkName}</Button>
        </NavItem>}
        <NavItem>
          <Button
            className="btn-round connect"
            color="primary"
            onClick={handleConnectWallet}
          >
            {me?.address
              ? truncateEthAddress(me.address)
              : "Connecter mon wallet sur la blockchain Ethereum"}
          </Button>
        </NavItem>
        {me && me.isOwner && <NavItem>
          <Button
            className="btn-round withdraw"
            color="danger"
            onClick={withdraw}
          >
            <i className="nc-icon nc-money-coins" /> Retirer mes gains sur "{networkName}"

            {withdrawing.loading && <Spinner animation="border" role="status"> </Spinner>}
            {withdrawing.tx && <span><br />{withdrawing.tx}</span>}
            {withdrawing.error && <span><br />{withdrawing.error}</span>}
          </Button>
        </NavItem>}
      </Nav>
    </Navbar >
  );
}

export default ColorNavbar;
