import React from "react";
import { Container, Row, Col } from "reactstrap";

function SectionNewsletter() {
  return (
    <div className="section landing-section">
      <Container>

        <Row>
          <Col className="ml-auto mr-auto" md="8" style={{ minHeight: "510px" }}>
            <iframe
              title="FrenchWeightliftingClubNewsletter"
              class="mj-w-res-iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://app.mailjet.com/widget/iframe/2R3X/NTe"
              width="100%"
              heigth="100%"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default SectionNewsletter;
