import { useEffect, useState } from "react";
import {
  Button, Row, Col, Spinner, Form, FormGroup, Label, Input
} from "reactstrap";
import Web3 from 'web3';

// context
import useEth from "contexts/EthContext/useEth";

function NftsDetails() {
  const {
    state: { me, contract, contractData, gasPrice }
  } = useEth();

  const [estimateGas, setEstimateGas] = useState("0");
  const [qty, setQty] = useState(1);

  const initialMinting = {
    tx: null,
    loading: false,
    error: null
  }
  const [minting, setMinting] = useState(initialMinting);

  const mint = async () => {
    if (me?.address && contractData) {
      try {
        setMinting({
          ...initialMinting,
          loading: true,
        })
        const res = await contract.methods
          .mint(me.address, qty)
          .send({ from: me.address, value: contractData.cost * qty });
        setMinting({
          ...initialMinting,
          tx: res.message,
          loading: false,
          isSuccess: true
        })
      } catch (err) {
        setMinting({
          ...initialMinting,
          error: err.message,
        })
      }
    }
  }

  useEffect(() => {
    if (me && me.address && contract) {
      contract.methods
        .mint(me.address, qty)
        .estimateGas({ gas: 5000000, from: me.address }, (error, gasAmount) => {
          let message = "";
          const ranOfGas = gasAmount === 5000000;
          if (ranOfGas || error) {
            message = ranOfGas
              ? "Dépassement des frais de gas"
              : `Transaction impossible, vérifiez le solde de votre wallet."`;
            console.error(error.message);
          } else {
            const totalGasPrice = (gasAmount * parseInt(gasPrice)).toString();
            message = "À partir de : " + Web3.utils.fromWei(totalGasPrice, 'ether').slice(0, 6) + " ETH"
          }
          setEstimateGas(message)
        });
    }
  }, [contract, gasPrice, me, qty]);

  const handleQtyChange = (e) => {
    setQty(e.target.value)
  }

  return (
    <Row>
      <Col lg="4" md="6">
        <div className="section-description">
          <h3 className="title">Collection "Men French Weightlifting Club"</h3>
          <h6 className="category">Devenez propriétaire d'un NFT</h6>

          {!me?.address && <p className="alert">Connectez votre porteuille Ethereum pour devenir propriétaire d'une de ces oeuvres numérique</p>}
          {contractData && <>
            <p>{contractData.totalSupply} / 50 adoptés</p>

            <Form>
              <Label for="qty">Quantité</Label>
              <FormGroup>
                <Input type="select" name="qty" id="qty" onChange={handleQtyChange} style={{ padding: "10px" }}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Input>
              </FormGroup>
            </Form>

            <h5 className="description">
              Prix : {Web3.utils.fromWei((contractData.cost * qty).toString())} ETH
            </h5>
            <p>Frais de carburant exclus.</p>
            <p>{estimateGas}</p>

            {!!me?.address && <Button
              className="btn btn-rose btn-round"
              color="primary"
              onClick={mint}
            >
              Acheter un NFT
              {minting.loading && <><br /><Spinner animation="border" role="status"> </Spinner></>}
            </Button>}
          </>}
          {minting.error && <p className="error">{minting.error}</p>}
        </div>
      </Col>
    </Row >
  );
}

export default NftsDetails;
